import { render, staticRenderFns } from "./AuthPositions.vue?vue&type=template&id=ab15234a&scoped=true&"
import script from "./AuthPositions.vue?vue&type=script&lang=js&"
export * from "./AuthPositions.vue?vue&type=script&lang=js&"
import style0 from "./AuthPositions.vue?vue&type=style&index=0&id=ab15234a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab15234a",
  null
  
)

export default component.exports