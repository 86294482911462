<template>
  <div>
    <h2 class="center-g">定位点审核</h2>
    <div v-for="(item,index) in positions" :key="index+'a'" class="sightbox">
      <el-descriptions
        :title="item.name+'---'+item.sight.name"
        :column="4"
        border
        style="margin:20px 0"
      >
        <template slot="extra" style="display:flex">
          <el-button type="primary" size="small" @click="getMapView(item.positionsforMap)">地图查看</el-button>
          <el-button type="danger" size="small" @click="rejectPositionFn(item)">拒绝</el-button>
          <el-button type="success" size="small" @click="approvePosition(item)">同意</el-button>
        </template>
        <el-descriptions-item
          v-for="(value, key) in positionHead"
          :key="key"
          :label="value"
        >{{item[key]}}</el-descriptions-item>
      </el-descriptions>
      <div class="btnwrap-g">
        <img
          v-for="(pic,index) in item.imgs"
          :key="index+'b'"
          :src="pic"
          width="80px"
          height="80px"
          alt
          @click="previewImg(pic)"
        />
      </div>
    </div>
    <el-dialog :visible.sync="showImg" top="2vh" destroy-on-close>
      <img :src="img" width="100%" alt />
    </el-dialog>
    <!-- <el-dialog :visible.sync="showMap" top="2vh" destroy-on-close>
      <mapview :positions="positionsforMap" />
    </el-dialog>-->
  </div>
</template>

<script>
import { getPositionsForAuth, authSight, rejectSight } from "@/api/api";
import { mapGetters } from "vuex";
// import mapview from "../components/MapView";
export default {
  // components: {
  //   mapview
  // },
  data() {
    return {
      showImg: false,
      positions: [],
      showMap: false,
      positionsforMap: [],
      img: "",
      positionHead: {
        name: "名称",
        typeName: "类别",
        idUser: "提交人",
        genTime: "提交时间",
        intro: "介绍"
      }
    };
  },
  created() {
    this.getPositionsForAuthFn();
  },
  computed: {
    ...mapGetters(["getFirstAuth"])
  },
  methods: {
    getPositionsForAuthFn() {
      const that = this;
      getPositionsForAuth().then(res => {
        if (res.result) {
          that.positions = res.data;
          that.getFirstAuth.position = that.positions.length;
          that.$store.dispatch("setFirstAuth", that.getFirstAuth);
        }
      });
    },

    previewImg(item) {
      this.img = item.replace(".thumb", "");
      this.showImg = true;
    },

    getMapView(positionsforMap) {
      this.positionsforMap = positionsforMap;
      this.showMap = true;
    },

    close() {
      this.showMap = false;
    },

    approvePosition(item) {
      const that = this;
      this.$confirm("确定审核通过?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          authSight({
            id: item.id
          }).then(res => {
            if (res.result) {
              this.$message({
                type: "success",
                message: item.name + "审核已通过"
              });
              that.getPositionsForAuthFn();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消"
          });
        });
    },

    rejectPositionFn(item) {
      const that = this;
      this.$prompt("请输入拒绝的原因", "拒绝", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^\S{1,}$/,
        inputErrorMessage: "请输入拒绝的原因",
        closeOnClickModal: false
      })
        .then(({ value }) => {
          rejectSight({
            id: item.id,
            remark: value
          }).then(res => {
            if (res.result) {
              this.$message({
                type: "error",
                message: item.name + "已拒绝"
              });
              that.getPositionsForAuthFn();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消"
          });
        });
    }
  }
};
</script>

<style scoped>
.sightbox {
  padding: 30px;
  margin: 30px;
  border: 1px solid #555;
  border-radius: 10px;
}
table td {
  text-align: center;
  padding: 0 10px;
}
</style>